import axios from "axios"

export function ServiceHelper(serviceUrl, requestObject, requestConfig = null) {
    this._serviceUrl = serviceUrl;
    this._requestObject = requestObject;
    this._requestConfig = requestConfig
    this._post = function (successCallBack, errorCallBack, requestConfig) {
        axios.post(this._serviceUrl, this._requestObject, this._requestConfig)
            .then((response) => {
                successCallBack(response);
            })
            .catch(err => {
                errorCallBack(err);
            })
    };

    this._get = function (successCallBack, errorCallBack) {
        axios.get(this._serviceUrl)
            .then((response) => {
                successCallBack(response);
            })
            .catch(err => {
                errorCallBack(err);
            })
    };
}