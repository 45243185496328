import React, { useState, useEffect, useContext } from 'react';
import { Col } from 'react-bootstrap';
import Select from 'react-select';
import { useSearchParams } from "react-router-dom";
import { AppContext } from '../contextapi/AppProvider';
import { useTranslation } from 'react-i18next';

const DropdownRenderer = (props) => {
    const { dropDownFilterData, setDropDownFilterData } = useContext(AppContext);
    const [selectedDropdown, setSelectedDropdown] = useState();
    const [searchParams, setSearchParams] = useSearchParams();
    const { t } = useTranslation();

    useEffect(() => {
        var cpDropDownFilterData = [...dropDownFilterData]
        var filteredData = cpDropDownFilterData.filter(item => item.key === props.element.key);
        if (!(filteredData.length > 0)) {
            cpDropDownFilterData.push({ key: props.element.key, ...props.element.values[0] });
            setSelectedDropdown(props.element.values[0].value)
            setDropDownFilterData(cpDropDownFilterData)
        }
        else {
            setSelectedDropdown(filteredData.value)
        }
    }, [])

    const getSelectedDropdownValue = () => {
        var selectedValue = searchParams.get(props.element.key);
        if(selectedValue){
            var filteredValue =  props.element.values.find(item => item.value === selectedValue)
            return {
                label:  t(`dynamic.dropdown.labels.${filteredValue.text}`,  filteredValue.text.toString()),
                value: selectedValue
            };

        }
        if(!props.element.values || !(props.element.values.length > 0))
            return  {};
            
        return {
            label: props.element.values[0].text,
            value: props.element.values[0].value
        }
    }

    const handleOnChange = (selectedOption) => {
        let generatedParams = { [props.element.key]: selectedOption.value }
        searchParams.forEach((paramValue, paramKey) => {
            if (paramKey === props.element.key)
                paramValue = selectedOption.value;
            generatedParams = {
                ...generatedParams,
                [paramKey]: paramValue
            };
        })

        var cpDropDownFilterData = [...dropDownFilterData]
        var filteredDataIndex = cpDropDownFilterData.findIndex(item => item.key === props.element.key);
        if (filteredDataIndex > -1) {
            cpDropDownFilterData[filteredDataIndex] = { key: props.element.key, value: selectedOption.value };
            setDropDownFilterData(cpDropDownFilterData)
        }

        setSelectedDropdown(selectedOption)
        setSearchParams(generatedParams)
    }

    const selectedDropdownValue = getSelectedDropdownValue();
    return (
        <Col md={props.element.column}>
            <div className='mb-2' style={props.element.style?.inlineStyle}>
                <Select
                    onChange={handleOnChange}
                    value={selectedDropdownValue}
                    options={props.element.values?.map(item => {
                        return {
                            label: t(`dynamic.dropdown.labels.${item.text}`, item.text.toString()),
                            value: item.value
                        }
                    })} />
            </div>
        </Col>
    );
}

export default DropdownRenderer;