
export const generatedRequestParams = (key, value) => {
    return {
        "type": "", "name": key, "value": value
    };
}

export const generateRequestFilterObjectFromQueryParams = (searchParams) => {
    var filters = []
    searchParams.forEach((value, key) => {
        filters.push(generatedRequestParams(key, value))
    })
    return filters;
}

export const generateRequestFilterObjectFromQueryParamsAndDropdownData = (searchParams, dropDownData) => {
    var filters = []
    searchParams.forEach((value, key) => {
        filters.push(generatedRequestParams(key, value))
    })

    dropDownData?.filter(p => !filters.some(x => x.name === p.key)).forEach(item => {
        filters.push(generatedRequestParams(item.key, item.value));
    })

    return filters;
}