import { CCard, CCardBody, CCardFooter, CCardHeader, CCol, CNavItem, CRow } from '@coreui/react';
import React, { useState, useContext, useEffect } from 'react';
import Chart from "react-apexcharts";
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { AppContext } from '../contextapi/AppProvider';
import { generateRequestFilterObjectFromQueryParamsAndDropdownData } from '../helpers/filter-helper';
import { ServiceHelper } from '../helpers/service-helper';


const DonutChartRenderer = (props) => {
    const { dropDownFilterData, currentLanguageCode } = useContext(AppContext)
    const [searchParams, setSearchParams] = useSearchParams()
    const { t } = useTranslation();
    const [componentData, setComponentData] = useState({ ...props })

    const [chartOptions, setChartOptions] = useState({
        options: {},
        series: [],
    })
    
    useEffect(() => {
        let obj = { ...chartOptions };
        obj.options = {
            chart: {
                height: 160,
                type: 'donut',
            },
            dataLabels: {
                enabled: false
            },
            plotOptions: {
                pie: {
                    donut: {
                        size: '78%'
                    }
                }
            },
            labels: [],//['Hedefe Kalan Paket Sayısı', 'Toplanan Paket Sayısı'],
            legend: {
                show: false
            },
            tooltip: {
                theme: 'dark'
            },
            grid: {
                padding: {
                    top: 20,
                    right: 0,
                    bottom: 0,
                    left: 0
                },
            },
            colors: [],
            fill: {
                opacity: [1, 1]
            },
            stroke: {
                width: 0
            }
        }
        if (componentData.element.url) {
            props.setLoading(true);
            var filters = generateRequestFilterObjectFromQueryParamsAndDropdownData(searchParams, dropDownFilterData);
            var _serviceHelper = new ServiceHelper(`${componentData.element.url}`, filters);
            _serviceHelper._post(
                (response) => {
                    obj.series = response.data.series.map(p => parseInt(p));
                    obj.options.labels = response.data.labels.map(item => {
                        return t(`dynamic.graphics.labels.${item}`, item.toString())
                    });
                    obj.options.colors = response.data.colors;

                    var tempComponentData = { ...componentData };
                    if (response.data.headers)
                        tempComponentData.element.cardInfo = {
                            ...tempComponentData.element.cardInfo,
                            headers: response.data.headers//[{ column: 6, inlineStyle: {}, text: "Test" }, { column: 6, style: { inlineStyle: {textAlign: "right"}}, text: "Test" }]
                        }
                    if (response.data.footers)
                        tempComponentData.element.cardInfo = {
                            ...tempComponentData.element.cardInfo,
                            footers: response.data.footers //[{ column: 12, inlineStyle: {}, text: "Test" }]
                        }

                    setChartOptions(obj)
                    setComponentData(tempComponentData);

                    props.setLoading(false);
                },
                (err) => {
                    console.log(err);
                    obj.series = [];
                    obj.options.labels = [];
                    obj.options.colors = [];
                    setChartOptions(obj)

                    props.setLoading(false);
                })
        }
        else {
            obj.series = componentData.element.series.map(p => parseInt(p));
            obj.options.labels = componentData.element.labels;
            obj.options.colors = componentData.element.colors ?? ["#f0f0f0", "#f36533"];
            setChartOptions(obj)
        }
    }, [currentLanguageCode])

    return (
        <>
            <CCard className="custom-card h-100">
                {componentData.element.cardInfo && componentData.element.cardInfo.headers && componentData.element.cardInfo.headers.length > 0 &&
                    <CCardHeader>
                        <CRow>
                            {
                                componentData.element.cardInfo.headers.map((p, key) => {
                                    return <CCol key={key} md={p.column}><h6 style={p.style?.inlineStyle}>{t(`dynamic.graphics.headers.${p.text}`, p.text.toString().toString())}</h6></CCol>
                                })
                            }
                        </CRow>
                    </CCardHeader>
                }
                <CCardBody>
                    <div className="chart">
                        <Chart
                            options={chartOptions.options}
                            series={chartOptions.series}
                            type={componentData.element.type}
                            height={350}
                        /></div>
                </CCardBody>
                {componentData.element.cardInfo && componentData.element.cardInfo.footers && componentData.element.cardInfo.footers.length > 0 &&
                    <CCardFooter>
                        <CRow>
                            {
                                componentData.element.cardInfo.footers.map((p, key) => {
                                    return <CCol key={key} md={p.column}><h6 style={p.style?.inlineStyle}>{t(`dynamic.graphics.footers.${p.text}`, p.text.toString())}</h6></CCol>
                                })
                            }
                        </CRow>
                    </CCardFooter>
                }
            </CCard>
        </>);
}

export default DonutChartRenderer;