import { CCol, CRow, CContainer } from "@coreui/react";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import { Col, Form, Modal } from 'react-bootstrap';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Switch from '@mui/material/Switch';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import Typography from '@mui/material/Typography';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Layout from '../../Layout';
import { CButton } from '@coreui/react';
import axios from "axios";
import { toastify } from '../../../helpers/common-helper';
import { LOCAL_STORAGE_KEYS } from "../../../helpers/constants";
import {
    Link
} from 'react-router-dom';
import Avatar from '@mui/material/Avatar';
import AlertTitle from '@mui/material/AlertTitle';
import { BookmarkRounded } from "@mui/icons-material";
import AssignmentRoundedIcon from '@mui/icons-material/AssignmentRounded';
import React, { useContext, useEffect, useState } from "react";
import { AppContext } from '../../../contextapi/AppProvider';



export default function CollapsibleTable() {
    const { setLoading, loading } = useContext(AppContext);
    const [showProjectModal, setShowProjectModal] = useState(false);
    const [showModelModal, setShowModelModal] = useState(false);
    const [projectDataTable, setProjectDataTable] = useState([]);
    const [modalType, setModalType] = useState(null);
    const [handleGroup, sethandleGroup] = useState(false);
    const [projectName, setProjectName] = useState(null);
    const [modelName, setmodelName] = useState(null);
    const [modelId, setModelId] = useState(null);
    const [labelList, setLabelList] = useState([]);
    const [activeProjectId, setActiveProjectId] = useState(null);
    const [models, setModels] = useState([]);

    const getModelsWithProjectId = (id) => {
        setLoading(true);
        axios.get(`${window.app.config.STATIC_NAVBAR.VISION_TOOL.MODELS_LIST_API_URL + '?projectId=' + id}`)
            .then(response => {
                setLoading(false);
                setModels(response.data);
            })
            .catch(err => {
                console.log(err);
                toastify.error(err);
                setLoading(false);
            })
    }

    useEffect(() => {
        getProjects();
    }, []);

    const getProjects = () => {
        setLoading(true);
        var userInfo = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.USER_INFO));
        var userId = userInfo.id; 
        axios.get(`${window.app.config.STATIC_NAVBAR.VISION_TOOL.PROJECT_LIST_API_URL + '?userId=' + userId}`)
            .then(response => {
                setProjectDataTable(response.data);
                console.log(response.data, 'response');
                setLoading(false);

            })
            .catch(err => {
                console.log(err);
                toastify.error(err);
                setLoading(false);
            })
    }

    const handleProjectModal = (id) => {
        if (id) {
            setModalType('edit');
        }
        else {
            setModalType('add');
        }
        setShowProjectModal(showProjectModal ? false : true);
    }

    const handleModelModal = (type) => {
        if (type === 'add') {
            setModalType('add');
        }
        else {
            setModalType('edit');
        }
        setShowModelModal(showModelModal ? false : true);

    }

    const EditModel = (model) => {
        handleModelModal('edit');
        setLabelList(model.labels);
        setModelId(model.id);
        setmodelName(model.modelName);
    };

    const ModelItemModal = () => {
        return (
            <Modal
                show={handleGroup}
                onHide={sethandleGroup(false)}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title> Doğruluk Değeri</Modal.Title>
                </Modal.Header>
                <Modal.Body >
                </Modal.Body>
                <Modal.Footer className="d-flex justify-content-center">
                </Modal.Footer>
            </Modal>
        )
    }
    return (
        <Layout>
            <Stack>
                <Stack marginBottom={5} className="d-flex justify-content-center m-2" direction="row" spacing={2}>
                    <TableRow>
                        <CButton className="custom-btn mb-4" onClick={() => { setModalType('add'); handleProjectModal(); }}> {' '}Create Project</CButton>
                    </TableRow>
                </Stack>
                <TableContainer sx={{ minWidth: 600, borderRadius: '16px' }} component={Paper}>
                    <Table aria-label="collapsible table">
                        <TableHead>
                            <TableRow>
                                <TableCell />
                                <TableCell>Project</TableCell>
                                <TableCell align="right">Image Count&nbsp; </TableCell>
                                <TableCell align="right">User Info&nbsp; </TableCell>
                                <TableCell align="right">Accuracy Value&nbsp;</TableCell>
                                <TableCell align="right">Date&nbsp;</TableCell>
                                <TableCell align="right"></TableCell>
                                <TableCell align="right"></TableCell>
                                <TableCell align="right"></TableCell>
                                <TableCell align="right"></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {projectDataTable?.map((row) => (
                                <Row key={row.id} row={row} EditModel={EditModel} getModelsWithProjectId={getModelsWithProjectId} models={models} handleModelModal={handleModelModal} handleProjectModal={handleProjectModal} projectDataTable={projectDataTable} setActiveProjectId={setActiveProjectId} />
                            ))}

                        </TableBody>
                    </Table>
                    <ModelItemModal />
                    <ModelModal getProjects={getProjects}
                        showModelModal={showModelModal}
                        modalType={modalType}
                        handleModelModal={handleModelModal}
                        projectDataTable={setProjectDataTable}
                        projectName={projectName}
                        setmodelName={setmodelName}
                        setModelId={setModelId}
                        modelId={modelId}
                        activeProjectId={activeProjectId}
                        getModelsWithProjectId={getModelsWithProjectId}
                        models={models}
                        modelName={modelName}
                        labelList={labelList}
                        setLabelList={setLabelList}
                    />
                    <ProjectModal getProjects={getProjects}
                        activeProjectId={activeProjectId}
                        showProjectModal={showProjectModal}
                        modalType={modalType}
                        handleProjectModal={handleProjectModal}
                        projectDataTable={setProjectDataTable}
                        projectName={projectName}
                        setProjectName={setProjectName}
                        setmodelName={setmodelName}
                        setModelId={setModelId}
                        modelId={modelId}
                        getModelsWithProjectId={getModelsWithProjectId}
                        models={models}
                        modelName={modelName}
                        labelList={labelList}
                        setLabelList={setLabelList}
                    />
                </TableContainer>
            </Stack >
        </Layout>

    );

}


const AddLabel = (props) => {
    const { labelList, setLabelList } = props;

    const deleteLabel = (name) => {
        const data = labelList.filter((item) => item.labelName !== name);
        setLabelList(data);
    }

    const handleOnClickLabelEdit = (labelInfo) => {
        var cpLabelList = [...labelList];
        var filteredLabel = cpLabelList.find(p => p.id === labelInfo.id);
        filteredLabel.editLabel = !labelInfo.editLabel;
        setLabelList(cpLabelList);
    }

    const onChangeLabelName = (labelInfo, value) => {
        var cpLabelList = [...labelList];
        var filteredLabel = cpLabelList.find(p => p.id === labelInfo.id);
        filteredLabel.labelName = value;
        setLabelList(cpLabelList);
    }


    const onChangeLabelColor = (labelInfo, value) => {
        var cpLabelList = [...labelList];
        var filteredLabel = cpLabelList.find(p => p.id === labelInfo.id);
        filteredLabel.color = value;
        setLabelList(cpLabelList);
    }

    const onChangeLabelDescription = (labelInfo, value) => {
        var cpLabelList = [...labelList];
        var filteredLabel = cpLabelList.find(p => p.id === labelInfo.id);
        filteredLabel.description = value;
        setLabelList(cpLabelList);
    }

    return (
        <TableContainer component={Paper}>
            {labelList?.length > 0 ?
                <>
                    <Table size="small" aria-label="a dense table">

                        <TableHead>
                            <TableRow>
                                <TableCell>Label Name</TableCell>
                                <TableCell> Description</TableCell>
                                <TableCell align="right"></TableCell>
                                <TableCell align="right"></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {labelList.map((row, index) => (
                                <TableRow
                                    key={index}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">
                                        <TextField
                                            fullWidth
                                            id={row.id}
                                            variant="standard"
                                            value={row.labelName}
                                            disabled={!row.editLabel}
                                            onChange={e => onChangeLabelName(row, e.target.value)}
                                        />
                                    </TableCell>
                                    {/* <TableCell align="right">{row.description}</TableCell> */}
                                    <TableCell align="right">
                                        <Form.Control
                                            type="color"
                                            id="exampleColorInput"
                                            defaultValue="#563d7c"
                                            title="Choose your color"
                                            disabled={!row.editLabel}
                                            value={row.color}
                                            onChange={e => onChangeLabelColor(row, e.target.value)}
                                        />
                                    </TableCell>
                                    <TableCell align="right">
                                        <TextField
                                            fullWidth
                                            id={row.id}
                                            variant="standard"
                                            value={row.description}
                                            disabled={!row.editLabel}
                                            onChange={e => onChangeLabelDescription(row, e.target.value)}
                                        />
                                    </TableCell>
                                    <TableCell align="right"> <IconButton onClick={() => handleOnClickLabelEdit(row)}> <EditIcon /> </IconButton></TableCell>
                                    <TableCell align="right"> <IconButton onClick={() => deleteLabel(row.labelName)}> <DeleteIcon /> </IconButton></TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table> </> : null}
        </TableContainer>
    )
}

const ModelModal = (props) => {
    const { modelId, modalType, setmodelName, handleModelModal, modelName, getProjects, setLabelList, labelList, activeProjectId, showModelModal } = props;
    const [labelName, setLabelName] = useState(null);
    const [labelColor, setLabelColor] = useState(null);
    const [labelDescription, setLabelDescription] = useState(null);
    const [validations, setValidations] = useState();
    const projectId = activeProjectId;
    const color = [
        {
            value: 'red',
            label: 'Red',
        },
        {
            value: 'yellow',
            label: 'Yellow',
        },
        {
            value: 'green',
            label: 'Green',
        }
    ];
    const validation = () => {
        const validations = {};
        if (!labelName) {
            validations.labelName = 'Gerekli alan';
            toastify.error("Label Adı boş geçilemez");

        }
        // if (!labelColor) {
        //     validations.labelColor = 'Gerekli alan';
        //     toastify.error("Label Color boş geçilemez");

        // }
        if (!labelDescription) {
            validations.labelDescription = 'Gerekli alan';
            toastify.error("Label Açıklaması boş geçilemez");

        }
        if (!modelName) {
            validations.modelName = 'Gerekli alan';
            toastify.error("Model adı boş geçilemez");
        }
        setValidations(validations);
    }

    const createLabel = () => {
        if (labelName && labelDescription) {
            setLabelList([...labelList, { labelName: labelName, color: labelColor, description: labelDescription }])
            setLabelName("");
            setLabelDescription("");
            setValidations("");
            setLabelColor("");
        }
        else {
            validation();
        }

    }

    const clearInputs = () => {
        setLabelName("");
        setLabelColor("");
        setLabelDescription("");
        setmodelName("");
        setLabelList("");
        setValidations("");
    }

    const CreateModel = () => {

        debugger
        if (modelName && Object.keys(labelList).length !== 0) {
            var userInfo = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.USER_INFO));
            var userId = userInfo.id;
            var labels = labelList;
            const data = {
                isActive: true,
                modelName: modelName,
                userId: userId,
                labels,
                projectId: projectId,
                isDeleted: false

            };
            axios.post(`${window.app.config.STATIC_NAVBAR.VISION_TOOL.MODEL_CREATE_API_URL}`, data)
                .then(response => {
                    console.log(response.data)
                    getProjects();
                    props.getModelsWithProjectId(activeProjectId)
                    toastify.success("Model Oluşturuldu");
                    handleModelModal();
                    clearInputs();
                })
                .catch(err => {
                    console.log(err);
                    toastify.error("Model Oluşturulamadı");

                })

        }
        else {
            const validations = {};

            if (!modelName) {
                validations.modelName = 'Gerekli alan';
                toastify.error("Model adı boş geçilemez");

            }
            if (!labelList) {
                validations.labelList = 'Gerekli alan';
                toastify.error("Label boş geçilemez");

            }
            setValidations(validations);
        }
    }
    const UpdateModel = () => {
        if (modelName && Object.keys(labelList).length !== 0) {
            var userInfo = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.USER_INFO));
            var userId = userInfo.id;
            const data = {
                id: modelId,
                isActive: true,
                projectId: activeProjectId,
                labels: labelList,
                modelName: modelName,
                userId: userId,
                isDeleted: false
            };
            axios.put(`${window.app.config.STATIC_NAVBAR.VISION_TOOL.MODEL_UPDATE_API_URL}`, data)
                .then(response => {
                    getProjects();
                    props.getModelsWithProjectId(activeProjectId)
                    toastify.success("Proje Güncellendi");
                    handleModelModal();
                    clearInputs();
                })
                .catch(err => {
                    console.log(err);
                    toastify.error("Proje Güncellenemedi");

                })
        }
        else {
            const validations = {};

            if (!modelName) {
                validations.modelName = 'Gerekli alan';
                toastify.error("Model adı boş geçilemez");

            }
            if (!Object.keys(labelList).length !== 0) {
                validations.labelList = 'Label Ekleyiniz';
                toastify.error("Label alanı boş geçilemez");
            }

            setValidations(validations);
        }
    }
    return (
        <Modal
            show={showModelModal}
            onHide={handleModelModal}
            backdrop="static"
            keyboard={false}
            dialogClassName="modal-60w"
            size={'lg'}

        >
            <Modal.Header closeButton>
                <Stack direction='row' alignItems="center" spacing={1}>
                    <Avatar sx={{ bgcolor: 'rgba(234, 91, 38)' }} >
                        <AssignmentRoundedIcon />
                    </Avatar>
                    <AlertTitle><strong> {modalType === "add" ? "Model Create" : "Model Edit"}</strong></AlertTitle>
                </Stack>
            </Modal.Header>
            <CContainer>
                <CRow className="justify-content-center">
                    <CCol md={12}  >

                        <CRow className="justify-content-center">
                            <CCol md={7}>
                                <Stack direction="row" justifyContent="center"
                                    spacing={5} paddingBottom={5} paddingTop={3}>

                                    <TextField
                                        fullWidth
                                        id="modelName"
                                        label="Model Name"
                                        variant="standard"
                                        helperText={validations?.modelName}
                                        value={modelName}
                                        onChange={e => setmodelName(e.target.value)}
                                    />
                                </Stack>
                            </CCol>
                        </CRow>
                    </CCol>
                </CRow>
                <CRow className="justify-content-center">
                    <CCol md={12}  >
                        <Stack
                        >        <Stack direction='row' alignItems="center" spacing={1}>
                                <Avatar sx={{ bgcolor: "rgba(234, 91, 38)", width: 30, height: 30 }} >
                                    <BookmarkRounded />
                                </Avatar>
                                <AlertTitle><strong> Label Create</strong></AlertTitle>
                            </Stack></Stack>
                        <hr />
                    </CCol>
                </CRow>
            </CContainer>

            <Modal.Body>
                <Stack direction="row" justifyContent="space-around" spacing={5} paddingBottom={5}>

                    <TextField
                        id="labelName"
                        label="Label Name"
                        variant="standard"
                        helperText={validations?.labelName}
                        value={labelName}
                        onChange={e => setLabelName(e.target.value)}
                    />
                    <FormControl variant="standard">
                        <Form.Control
                            type="color"
                            id="exampleColorInput"
                            defaultValue="#563d7c"
                            title="Choose your color"
                            onChange={e => setLabelColor(e.target.value)}
                        />
                    </FormControl>
                    <TextField
                        id="description"
                        label="Description"
                        variant="standard"
                        value={labelDescription}
                        onChange={e => setLabelDescription(e.target.value)}
                        helperText={validations?.labelDescription}

                    />

                    <CButton color="light" onClick={() => createLabel()}>Add Label</CButton>

                </Stack>
            </Modal.Body>
            < AddLabel labelList={labelList} setLabelList={setLabelList} />
            <Modal.Footer className="d-flex justify-content-center">
                <CButton color="light" onClick={() => modalType === 'add' ? CreateModel() : UpdateModel()}>{" "}{modalType === 'add' ? "Create" : "Update"} </CButton>
            </Modal.Footer>
        </Modal>
    )
}

const ProjectModal = (props) => {
    const { activeProjectId, showProjectModal, modalType, handleProjectModal, projectName, setProjectName, setmodelName, modelName, getProjects, setLabelList, labelList } = props;
    const [OCR, setOCR] = useState(false);

    const [validations, setValidations] = useState();

    const clearInputs = () => {
        setProjectName("");
        setmodelName("");
        setLabelList("");
        setValidations("");

    }

    const Update = () => {

        if (projectName) {
            var userInfo = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.USER_INFO));
            var userId = userInfo.id;

            const data = {
                id: activeProjectId,
                isActive: true,
                projectName: projectName,
                userId: userId,
                isDeleted: false

            };
            axios.put(`${window.app.config.STATIC_NAVBAR.VISION_TOOL.PROJECT_UPDATE_API_URL}`, data)
                .then(response => {
                    getProjects();
                    toastify.success("Proje Güncellendi");
                    handleProjectModal();
                    clearInputs();
                })
                .catch(err => {
                    console.log(err);
                    toastify.error("Proje Güncellenemedi");

                })

        }
        else {
            const validations = {};
            if (!projectName) {
                validations.projectName = 'Gerekli alan';
                toastify.error("Proje adı boş geçilemez");

            }
            if (!modelName) {
                validations.modelName = 'Gerekli alan';
                toastify.error("Model adı boş geçilemez");

            }
            if (!Object.keys(labelList).length !== 0) {
                validations.labelList = 'Label Ekleyiniz';
                toastify.error("Label alanı boş geçilemez");
            }

            setValidations(validations);

        }
    }
    const createProject = () => {

        if (projectName) {
            var userInfo = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.USER_INFO));
            var userId = userInfo.id;

            const data = {
                isActive: true,
                projectName: projectName,
                userId: userId,
                isDeleted: false

            };

            axios.post(`${window.app.config.STATIC_NAVBAR.VISION_TOOL.PROJECT_CREATE_API_URL}`, data)
                .then(response => {
                    console.log(response.data)
                    getProjects();
                    toastify.success("Proje Oluşturuldu");
                    handleProjectModal();
                    clearInputs();
                })
                .catch(err => {
                    console.log(err);
                    toastify.error("Proje Oluşturulamadı");

                })

        }
        else {
            const validations = {};
            if (!projectName) {
                validations.projectName = 'Gerekli alan';
                toastify.error("Proje adı boş geçilemez");

            }
            setValidations(validations);
        }
    }

    return (
        <Modal
            show={showProjectModal}
            onHide={handleProjectModal}
            backdrop="static"
            keyboard={false}
            onShow={modalType === "add" ? clearInputs : null}
            dialogClassName="modal-60w"
            size={'lg'}
        >
            <Modal.Header closeButton>
                <Stack direction='row' alignItems="center" spacing={1}>
                    <Avatar sx={{ bgcolor: 'rgba(234, 91, 38)' }} >
                        <AssignmentRoundedIcon />
                    </Avatar>
                    <AlertTitle><strong> {modalType === "add" ? "Project Create" : "Project Edit"}</strong></AlertTitle>
                </Stack>
            </Modal.Header>
            <CContainer>
                <CRow className="justify-content-center mt-4 mb-5">
                    <CCol md={7} >
                        <TextField
                            fullWidth
                            id="projectname"
                            label="Project Name"
                            variant="standard"
                            value={projectName}
                            helperText={validations?.projectName}
                            onChange={e => setProjectName(e.target.value)}
                        />
                        {/* <Stack
                            marginTop={3}
                            alignItems="center"
                            direction="row">
                            <FormControlLabel
                                value="start"
                                control={<Switch color="warning" onChange={e => setOCR(e.target.value === 'on' ? true : false)} />}
                                label="Ocr"
                                labelPlacement="start"
                            />

                        </Stack> */}
                    </CCol>
                </CRow>
                {/* <Stack justifyContent="center"
                            alignItems="center " direction="row" spacing={2}>

                            <Box
                                sx={{
                                    width: 100,
                                    height: 100,
                                    border: 1,
                                    borderColor: 'gray',
                                    borderRadius: '5%',
                                    bgcolor: 'white'
                                }}

                            >
                            </Box>
                            <Stack spacing={1}  >

                                <Chip label="Upload"
                                    sx={{ fontSize: 15, paddingTop: 2, paddingBottom: 2 }}
                                    onClick={() => console.log('')}
                                    icon={<FileUploadOutlinedIcon />}
                                />
                                <Chip sx={{ fontSize: 15, paddingTop: 2, paddingBottom: 2 }}
                                    label="Remove" onClick={() => console.log('')} icon={<CancelOutlinedIcon />} />
                            </Stack>
                        </Stack> */}

            </CContainer>

            <Modal.Footer className="d-flex justify-content-center">
                <CButton color="light" onClick={modalType === 'add' ? createProject : Update}>{' '}{modalType === 'add' ? "Create" : "Update"} </CButton>
            </Modal.Footer>
        </Modal>
    )
}

function Row(props) {

    const { setActiveProjectId, row, handleValueShow, EditModel, getModelsWithProjectId, models, handleModelModal, handleProjectModal, projectDataTable } = props;
    const [open, setOpen] = useState(false);
    const [modelId, setModelId] = useState(null);
    const [modelSwitch, setModeSwitch] = useState(null);
    const handleCheckboxChange = (event) => {
        setModeSwitch(event.target.checked);
        if (modelSwitch) {
            setModeSwitch(false);
            setModelId(null);
        }
        else {
            setModeSwitch(true);
        }

    };
    const handleOpenedTab = (id) => {
        setOpen(!open);
        setActiveProjectId(id);
        getModelsWithProjectId(id);
    }
    return (
        <React.Fragment  >
            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                <TableCell>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => handleOpenedTab(row.id)}>
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>

                <TableCell component="th" scope="row">
                    {row.projectName}
                </TableCell>
                <TableCell align="right"></TableCell>
                <TableCell align="right">{row.userFullName}</TableCell>
                <TableCell align="right"></TableCell>
                <TableCell align="right">{row.insertDate}</TableCell>
                <TableCell align="right">{' '}</TableCell>
                <TableCell align="right">
                    <CButton color="light" onClick={() => handleProjectModal(row.id)}><EditIcon /></CButton> {' '}
                    <CButton color="light" onClick={() => { setActiveProjectId(row.id); handleModelModal('add'); }}>Create Model</CButton> {' '}
                    {modelId ?
                        <CButton color="light" component={Link} to="/annotationtool" state={{ projectId: row.id, modelId: modelId, selectedModel: models.find(p => p.id === modelId) }}>Annotation Tool</CButton>
                        : <CButton color="light" disabled >Annotation Tool</CButton>}
                </TableCell>

            </TableRow>
            <TableRow sx={{ minWidth: 680, borderRadius: '16px' }} >
                <TableCell
                    style={{ backgroundColor: "rgba(2, 2, 2,0.1)", paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Typography variant="h6" gutterBottom component="div">
                            Model
                        </Typography>
                        <Table size="small" aria-label="purchases">
                            <TableHead>
                                <TableRow >
                                    <TableCell ></TableCell>
                                    <TableCell>Project Name</TableCell>
                                    <TableCell align="left">User</TableCell>
                                    <TableCell align="left">Accuracy Value</TableCell>
                                    <TableCell align="left">Label Count</TableCell>
                                    <TableCell align="left">Date</TableCell>
                                    <TableCell align="left"></TableCell>
                                    <TableCell align="left"></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>

                                {models?.map((item, i) => (
                                    item.projectId === row.id ?
                                        <TableRow key={i}>
                                            <TableCell>        <FormControl>
                                                <Switch
                                                    id={i}
                                                    color="warning"
                                                    onChange={(e) => { setModelId(item.id); handleCheckboxChange(e) }}
                                                    checked={modelId === item.id ? true : false}
                                                // value={modelSwitch}
                                                />
                                                <FormHelperText id="project-name-text">Attach</FormHelperText>
                                            </FormControl></TableCell>
                                            <TableCell component="th" scope="row"> {item.modelName}</TableCell>
                                            <TableCell align="left">{item.userFullName}</TableCell>
                                            <TableCell > <Box sx={{
                                                width: 40,
                                                height: 40,
                                                borderColor: 'gray',
                                                borderRadius: 1
                                            }} >     <Button variant="outlined" size="small" onClick={() => handleValueShow()}>
                                                    {row.amount}
                                                </Button></Box>
                                            </TableCell>
                                            <TableCell > <Box sx={{
                                                width: 40,
                                                height: 40,
                                                borderColor: 'gray',
                                                borderRadius: 1
                                            }} >     <Button variant="outlined" size="small" onClick={() => handleValueShow()}>
                                                    {row.amount}
                                                </Button></Box>
                                            </TableCell>
                                            <TableCell align="left">{row.insertDate}</TableCell>
                                            <TableCell align="right"><IconButton onClick={() => EditModel(item)} aria-label="edit">
                                                <EditIcon />
                                            </IconButton>
                                            </TableCell>
                                        </TableRow>
                                        : null))}
                            </TableBody>
                        </Table>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment >
    );
}









