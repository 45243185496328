export const RectFunctions = {
    RectForMarker: (function () {
        // constructor
        function rect(id, x, y, markerText, markerImg, canvas) {
            this.x = x;
            this.y = y;
            this.id = id;
            this.markerImg = markerImg;
            this.width = 15;
            this.height = 30;
            this.stroke = false;
            this.ctx = canvas.getContext("2d");
            this.markerText = markerText;
            this.redraw(this.x, this.y);
            return (this);
        }
        rect.prototype.redraw = function (x, y) {
            this.x = x || this.x;
            this.y = y || this.y;
            this.draw(false);
            return (this);
        }
        //
        rect.prototype.highlight = function (x, y) {
            this.x = x || this.x;
            this.y = y || this.y;
            this.draw(true);
            return (this);
        }
        //
        rect.prototype.draw = function (stroke) {
            this.ctx.save();
            this.ctx.beginPath();
            this.ctx.drawImage(this.markerImg, this.x, this.y, this.width, this.height);

            if (stroke == true) {
                var textMeasurements = this.ctx.measureText(this.markerText);
                this.ctx.font = 'bolder 16px Calibri';
                this.ctx.fillStyle = "black";
                this.ctx.globalAlpha = 0.7;
                this.ctx.fillRect(this.x - 5, this.y - 15, textMeasurements.width + 20, 20);
                this.ctx.globalAlpha = 1;

                // Draw position above
                this.ctx.fillStyle = "white";
                this.ctx.fillText(this.markerText, this.x, this.y);
            }

            this.ctx.stroke();
            this.ctx.fill();
            this.ctx.restore();
        }
        //
        rect.prototype.isPointInside = function (x, y) {
            return (x >= this.x && x <= this.x + this.width && y >= this.y && y <= this.y + this.height);
        }
        return rect;
    })(),
    RectForHeat: (function () {
        // constructor
        function rect(id, x, y, width, height, markerText, color, canvas) {
            this.x = x;
            this.y = y;
            this.id = id;
            this.width = width;
            this.height = height;
            this.stroke = false;
            this.ctx = canvas.getContext("2d");
            this.markerText = markerText;
            this.color = color;
            this.redraw(this.x, this.y);
            return (this);
        }
        rect.prototype.redraw = function (x, y) {
            this.x = x || this.x;
            this.y = y || this.y;
            this.draw(false);
            return (this);
        }
        //
        rect.prototype.highlight = function (x, y) {
            this.x = x || this.x;
            this.y = y || this.y;
            this.draw(true);
            return (this);
        }
        //
        rect.prototype.draw = function (stroke) {

            this.ctx.save();

            this.ctx.beginPath();
            this.ctx.rect(this.x, this.y, this.width, this.height);
            //x, y, w, h
            this.ctx.fillStyle = this.color;
            this.ctx.fill();
            this.ctx.lineWidth = 1;
            this.ctx.strokeStyle = '#d9d9d9';
            this.ctx.stroke();

            if (stroke == true) {
                var textMeasurements = this.ctx.measureText(this.markerText);
                this.ctx.font = 'bolder 12px Calibri';
                this.ctx.fillStyle = "black";
                this.ctx.globalAlpha = 0.7;

                this.ctx.fillRect(this.x - 20, this.y - 30, textMeasurements.width + 20, 20);
                this.ctx.globalAlpha = 1;

                // Draw position above
                this.ctx.fillStyle = "white";
                this.ctx.fillText(this.markerText, this.x - 15 , this.y - 15);
                this.ctx.globalAlpha = 2;

            }
            this.ctx.restore();
        }
        //
        rect.prototype.isPointInside = function (x, y) {
            return (x >= this.x && x <= this.x + this.width && y >= this.y && y <= this.y + this.height);
        }


        return rect;
    })()
}


export const CanvasFunctions = {
    Marker: function (markerImgUrl) {
        this.MarkerImage = new Image();
        this.MarkerImage.src = markerImgUrl;
        this.Width = 15;
        this.Height = 30;
        this.XPos = 0;
        this.YPos = 0;
    },

    DrawCanvasImage: function (canvas, mapImg) {
        // Clear Canvas
        var ctx = canvas.getContext("2d");

        ctx.font = "15px Georgia";
        ctx.textAlign = "center";

        ctx.canvas.width = mapImg.width;
        ctx.canvas.height = mapImg.height;

        ctx.fillStyle = "#000";
        ctx.fillRect(0, 0, canvas.width, canvas.height);

        // Draw map
        // Sprite, X location, Y location, Image width, Image height
        // You can leave the image height and width off, if you do it will draw the image at default size
        ctx.drawImage(mapImg, 0, 0, mapImg.width, mapImg.height);
    },

    HandleMouseMove: function (e, canvas, mapSprite, offsetX, offsetY, rectObject) {
        var mouseX = parseInt(e.clientX - offsetX);
        var mouseY = parseInt(e.clientY - offsetY);

        // Put your mousemove stuff here
        //ctx.clearRect(0, 0, canvas.width, canvas.height);
        this.DrawCanvasImage(canvas, mapSprite);
        var selectedObject = null;
        for (var i = 0; i < rectObject.length; i++) {
            if (rectObject[i].isPointInside(mouseX, mouseY)) {
                selectedObject = rectObject[i];
            } else {
                rectObject[i].redraw();
            }
        }
        if (selectedObject)
            selectedObject.highlight();
    },
}