import {
  CCard,
  CCardBody,
  CCardImage,
  CCardText,
  CModal,
  CModalBody,
  CCol,
  CPagination,
  CPaginationItem,
  CRow,
} from "@coreui/react";
import {
  LOCAL_STORAGE_KEYS,
  PROJECT_DETAILS_LIST_TYPES,
} from "../../helpers/constants";
import { useContext, useEffect, useState, useRef } from "react";
import { AppContext } from "../../contextapi/AppProvider";
import axios from "axios";
import { toastify } from "../../helpers/common-helper";
import Layout from "../Layout";
import { useTranslation } from "react-i18next";
import ModalRenderer from "../../components/ModalRenderer";
import { PaginationControl } from "react-bootstrap-pagination-control";

const MainPage = () => {
  const { setLoading, loading } = useContext(AppContext);
  const [formData, setFormData] = useState([]);
  const [selectedImagePath, setSelectedImagePath] = useState("");
  const [imgVisible, setImgVisible] = useState(false);
  const [page, setPage] = useState(1);

  const previousFormDataRef = useRef(null); // Bir önceki formData'yı saklamak için

  const { t } = useTranslation();

  const fetchData = () => {
    var userInfo = JSON.parse(
      localStorage.getItem(LOCAL_STORAGE_KEYS.USER_INFO)
    );
    var startPage = parseInt(page);
    var userId = userInfo.id;
    axios
      .post(
        `${window.app.config.STATIC_NAVBAR.VISION_TOOL.PROJECTDETAIL_API_URL}`,
        { startPage, userId }
      )
      .then((response) => {
        const newData = response.data;
        // Eğer yeni veri, eski veriden farklıysa sayfayı yenile
        if (
          JSON.stringify(newData) !==
          JSON.stringify(previousFormDataRef.current)
        ) {
          setFormData(newData);
          previousFormDataRef.current = newData; // Güncel veriyi sakla
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        toastify.error(err.toString());
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchData(); // İlk yüklemede veriyi getir
    const intervalId = setInterval(fetchData, 5000); // 5 saniyede bir fetch yap

    return () => clearInterval(intervalId); // Bileşen unmount olduğunda interval'ı temizle
  }, [page]);

  const formatDateTime = (dateString) => {
    const date = new Date(dateString);
    const options = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: true,
    };
    return date.toLocaleString("en-US", options);
  };

  const onClickImage = (e) => {
    setSelectedImagePath(e.target.src);
    setImgVisible(true);
  };

  const onClickPage = (pageNumber) => {
    if (pageNumber <= 0 || pageNumber > formData?.paggingCount) {
      setPage(1);
    } else {
      setPage(pageNumber);
    }
  };

  return (
    <Layout>
      <CRow>
        {formData?.productImage?.map((item) => {
          return (
            <CCol md={6} key={item.id}>
              <CCard className="custom-card p-3 mb-5 bg-white rounded">
                <CCardBody>
                  <CRow>
                    <CCol md={8}>
                      <CCardImage
                        onClick={(e) => onClickImage(e)}
                        className="pointer"
                        style={{ borderRadius: 15 }}
                        orientation="top"
                        src={item.imagePath}
                      />
                    </CCol>
                    <CCol md={4}>
                      <CCardBody>
                        <CCardText>{formatDateTime(item.insertDate)}</CCardText>
                        <CCardText>{item.mlResult}</CCardText>
                        <CCardText>
                          {item.errorReasonName !== ""
                            ? item.errorReasonName
                            : ""}
                        </CCardText>
                      </CCardBody>
                    </CCol>
                  </CRow>
                </CCardBody>
              </CCard>
            </CCol>
          );
        })}
        <PaginationControl
          page={page}
          between={4}
          total={formData.paggingCount > 0 ? formData.paggingCount : 1}
          limit={10}
          changePage={(page) => {
            setPage(page);
            console.log(page);
          }}
          ellipsis={1}
        />
      </CRow>
      <ModalRenderer
        visible={imgVisible}
        setVisible={setImgVisible}
        title="Test"
      >
        <img width={"100%"} src={selectedImagePath} />
      </ModalRenderer>
    </Layout>
  );
};
export default MainPage;
