import React from 'react';
import WalkingGif from '../../../assets/gifs/walking.gif'
import WalkingPNG from '../../../assets/gifs/walking.png'
import StandingGif from '../../../assets/gifs/standing.gif'
import StandingPNG from '../../../assets/gifs/standing.png'
import WorkingGif from '../../../assets/gifs/working.gif'
import WorkingPNG from '../../../assets/gifs/working.png'
import { Col, Row } from 'react-bootstrap';
import { CCard, CCardBody } from '@coreui/react';
import { IMU_TYPE } from '../../../helpers/constants';
import classNames from 'classnames';
import { useEffect } from 'react';
import { useContext } from 'react';
import { AppContext } from '../../../contextapi/AppProvider';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

const IMUPage = (props) => {
    const { imuValue, setImuValue, userInfo } = useContext(AppContext);
    const { t } = useTranslation();

    useEffect(() => {
        const intervalId = setInterval(() => {  //assign interval to a variable to clear it.
            axios.get(`${window.app.config.PICKING_TOOL.IMU.API_URL}`,
                {
                    headers: {
                        UserId: userInfo.id,
                        UserName: userInfo.userName
                    }
                })
                .then((imuResponse) => {
                    if(parseInt(imuResponse.data.data) !== IMU_TYPE.NONE)
                        setImuValue(parseInt(imuResponse.data.data));
                })
        }, 1000)

        return () => clearInterval(intervalId); //This is important
    }, [])

    return (
        <Col md={12} className="d-flex justify-content-center">
            <CCard className="custom-card p-3 mb-5 bg-white rounded">
                <CCardBody>
                    <Row>
                        <Col md={4} className={classNames('imu_gif', { "active": imuValue === IMU_TYPE.WALKING })}>
                            <h6>{t("pages.picking.walking")}</h6>
                            <img src={imuValue === IMU_TYPE.WALKING ? WalkingGif : WalkingPNG} alt="walking_gif" height={150} />
                        </Col>
                        <Col md={4} className={classNames('imu_gif', { "active": imuValue === IMU_TYPE.WORKING })}>
                            <h6>{t("pages.picking.working")}</h6>
                            <img src={imuValue === IMU_TYPE.WORKING ? WorkingGif : WorkingPNG } alt="working_gif" height={150} />
                        </Col>
                        <Col md={4} className={classNames('imu_gif', { "active": imuValue === IMU_TYPE.STANDING })}>
                            <h6>{t("pages.picking.standing")}</h6>
                            <img src={imuValue === IMU_TYPE.STANDING ? StandingGif : StandingPNG } alt="standing_gif"  height={150} />
                        </Col>
                    </Row>
                </CCardBody>
            </CCard>
        </Col>
    );
}

export default IMUPage;