import { CCard, CAlert, CIcon, CCardBody, CCol, CRow, CContainer, CCardHeader, CFormSelect, CFormTextarea, CButton, CFormInput } from "@coreui/react";
import Layout from '../Layout';
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ELEMENT_TYPE, LOCAL_STORAGE_KEYS, PROJECT_DETAILS_LIST_TYPES } from "../../helpers/constants";
import { AppContext } from '../../contextapi/AppProvider';
import { Card, ModalBody } from "react-bootstrap";
import MUIDataTable from 'mui-datatables';
import { getKeyByValue, toastify } from '../../helpers/common-helper';
import Chip from '@mui/material/Chip';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import Stack from '@mui/material/Stack';
import { Modal } from 'react-bootstrap';
import TextField from '@mui/material/TextField';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import MenuItem from '@mui/material/MenuItem';
import { useRef } from 'react';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import dayjs, { Dayjs } from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';

import Avatar from '@mui/material/Avatar';
import { blue, green, orange, pink } from '@mui/material/colors';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import { WorkHistoryOutlined } from "@mui/icons-material";
import { Group } from "@material-ui/icons";
import CalendarMonthRoundedIcon from '@mui/icons-material/CalendarMonthRounded';

import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';

const TaskAssignment = () => {
    const { setLoading, loading } = useContext(AppContext);
    const [formData, setFormData] = useState([]);
    const { t } = useTranslation();
    const [selectedGroup, setSelectedGroup] = useState(null)
    const [selectedUser, setSelectedUser] = useState(null)
    const [usersData, setUsersData] = useState([]);
    const [message, setMessage] = useState()
    const [showEditModal, setShowEditModal] = useState(false);
    const [projectName, setProjectName] = useState(null);
    const [showCreateElements, setShowCreateElements] = useState(false);
    const [value, setValue] = useState(null);
    const [workType, setWorkType] = useState(null);
    const [isMomentaryChecked, setisMomentaryChecked] = useState(true);
    const [isRegularJobChecked, setisRegularJobChecked] = useState(false);
    const [isAllGroupChecked, setisAllGroupChecked] = useState(false);
    const [isAllUsersChecked, setisAllUsersChecked] = useState(false);
    const [isFreelancerChecked, setisFreelancerChecked] = useState(false);
    const [allProjects, setAllProjects] = useState([]);
    const [allGroups, setAllGroups] = useState([]);
    const [groupUsers, setGroupUsers] = useState([]);
    const [projectTemplates, setProjectTemplates] = useState([]);
    const [selectedTemplate, setSelectedTemplate] = useState(null);
    const [notification, setNotification] = useState(null);
    const [taskName, setTaskName] = useState(null);

    const worksType = [
        {
            value: 'momentary',
            label: 'Anlık İş',
        },
        {
            value: 'regularjob',
            label: 'Düzenli İş',
        }
    ];

    useEffect(() => {
        setLoading(true);
        Promise.all([getAllProjects(), gelAllGroups()])
            .then(response => {
                setLoading(false);
            })
            .catch((err) => {
                toastify.error(err.toString());
                setLoading(false);
            });
    }, [])


    const clearInputs = () => {
        setShowCreateElements(true);
        setProjectName(null);
    }

    const handleModal = () => { setShowEditModal(showEditModal ? false : true); clearInputs(); }

    const columns = [
        {
            name: "Username",
            label: t(`dynamic.datatable.columns.Username`),
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: "deviceId",
            label: t(`dynamic.datatable.columns.DeviceName`),
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: "message",
            label: t(`dynamic.datatable.columns.Message`),
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: "status",
            label: t(`dynamic.datatable.columns.Status`),
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: "insertDate",
            label: t(`dynamic.datatable.columns.insertDate`),
            options: {
                filter: true,
                sort: true,
            }
        }
    ];

    const options = {
        // filterType: "dropdown",
        download: false,
        filterType: "textField",
        print: false,
        selectToolbarPlacement: 'none',

    };

    const getAllProjects = () => {
        axios.get(`${window.app.config.TASKMANAGEMENT.TASK_ASSIGNMENT_PROJECTS_URL}`)
            .then((res) => {
                setAllProjects(res.data)
            })
            .catch((err) => {
                toastify.error(err.toString())
            })
    }

    const getTemplatesByProjectId = (projectId) => {
        setLoading(true)
        axios.post(`${window.app.config.TASKMANAGEMENT.TASK_ASSIGNMENT_TEMPLATES_URL}?projectId=${projectId}`)
            .then((res) => {
                setProjectTemplates(res.data)
                setLoading(false)
            })
            .catch((err) => {
                toastify.error(err.toString())
                setLoading(false)
            })
    }

    const gelAllGroups = () => {
        axios.get(window.app.config.TASKMANAGEMENT.TASK_ASSIGNMENT_GET_GROUPS)
            .then((res) => {
                setAllGroups(res.data);
            }) // Handle the response from backend here
            .catch((err) => {
                toastify.error(err.toString());
                setAllGroups([]);
            }); // Catch errors if any
    }

    const getAllUsersByGroupId = (groupId) => {
        setLoading(true);
        axios.get(`${window.app.config.TASKMANAGEMENT.TASK_ASSIGNMENT_GET_USERS}?groupId=${groupId}`)
            .then((res) => {
                
                setGroupUsers(res.data);
                setLoading(false);
            }) // Handle the response from backend here
            .catch((err) => {
                toastify.error(err.toString());
                setGroupUsers([]);
                setLoading(false);
            }); // Catch errors if any
    }

    const handleOnSelectProjectElement = (projectId) => {
        
        getTemplatesByProjectId(projectId);
    }

    const handleOnSelectTemplateElement = (templateId) => {
        var selectedTemplate = projectTemplates.find(p => p.id === templateId)
        if (!selectedTemplate) {
            toastify.error("There is no found template.");
            return;
        }

        setSelectedTemplate(selectedTemplate);
    }

    const handleOnChangeFormValues = (element, value) => {
        var cpSelectedTemplate = { ...selectedTemplate }
        var selectedTemplateObject = cpSelectedTemplate.objectTypes.find(p => p.key === element.key);
        selectedTemplateObject.value = value;
        setSelectedTemplate(cpSelectedTemplate);
    }

    const handleOnSelectGroupElement = (groupId) => {
        
        setSelectedGroup(groupId);
        getAllUsersByGroupId(groupId);
    }

    const handleSendMessage = () => {
        var userMessage = message
        var requestObj = {
            taskName: taskName,
            userId: selectedUser,
            groupId: selectedGroup,
            isAllGroup: isAllGroupChecked,
            isAllUser: isAllUsersChecked,
            advanceTaskManagementTemplateId: selectedTemplate.id,
            templateObject: JSON.stringify(selectedTemplate.objectTypes),
            taskObject: JSON.stringify(selectedTemplate.objectTypes),
            isActive: true,
            isDeleted: false
        }
        setLoading(true)
        axios.post(`${window.app.config.TASKMANAGEMENT.TASK_INSERT_API_URL}`, requestObj)
            .then(response => {
                console.log(response.data)
                toastify.success(t("toastify.Save_Success"))
                setLoading(false);
            })
            .catch(err => {
                console.log(err);
                toastify.error(err.toString())
                setLoading(false);

            })
    }

    const handleMomentaryChange = (event) => {
        setisMomentaryChecked(event.target.checked);
        if (isRegularJobChecked) {
            setisRegularJobChecked(false);
        }
        else if (!isRegularJobChecked && !event.target.checked)
            setisRegularJobChecked(true);
    };

    const handleRegularJobChange = (event) => {
        setisRegularJobChecked(event.target.checked);
        if (isMomentaryChecked) {
            setisMomentaryChecked(false);
        }
        else if (!isMomentaryChecked && !event.target.checked)
            setisMomentaryChecked(true);

    };

    const handleAllGroupChange = (event) => {
        setisAllGroupChecked(event.target.checked);
        if (isAllUsersChecked || isFreelancerChecked) {
            setisAllUsersChecked(false);
            setisFreelancerChecked(false);
        }


    };

    const handleAllUsersChange = (event) => {
        setisAllUsersChecked(event.target.checked);
        if (isAllGroupChecked || isFreelancerChecked) {
            setisFreelancerChecked(false);
            setisAllGroupChecked(false);
        }


    };

    const handleFreelancerChange = (event) => {
        setisFreelancerChecked(event.target.checked);
        if (isAllUsersChecked || isAllGroupChecked) {
            setisAllUsersChecked(false);
            setisAllGroupChecked(false);
        }


    };

    return (
        <Layout>

            {/* <CCol md={12} >
                <CCard className="custom-card bg-white rounded ">
                    <CCardHeader >{t("pages.taskmanagement.taskmanagementlist")}</CCardHeader>
                    <CCardBody>
                        <CRow md={6}>
                            <MUIDataTable
                                title={t(`dynamic.datatable.headers.task_management`)}
                                data={formData}
                                columns={columns}
                                options={options}
                            />
                        </CRow>

                    </CCardBody>

                </CCard>


            </CCol> */}
            <CRow>
                <CCol md={6}>
                    <TextField
                        style={{ width: "100%" }}
                        select
                        label="Projects"
                        //helperText={validations?.labelColor}
                        variant="standard"
                        //value={inputType}
                        onChange={e => { handleOnSelectProjectElement(e.target.value); }}
                    >
                        {allProjects.map((option) => (
                            <MenuItem key={option.id} value={option.id}>
                                {option.projectName}
                            </MenuItem>
                        ))}
                    </TextField>
                </CCol>
                <CCol md={6}>
                    <TextField
                        style={{ width: "100%" }}
                        select
                        label="Templates"
                        //helperText={validations?.labelColor}
                        variant="standard"
                        //value={inputType}
                        onChange={e => { handleOnSelectTemplateElement(e.target.value); }}
                    >
                        {projectTemplates.map((option) => (
                            <MenuItem key={option.id} value={option.id}>
                                {option.name}
                            </MenuItem>
                        ))}
                    </TextField>
                </CCol>
            </CRow>
            <CCard style={{ marginTop: '20px', marginBottom: '20px' }}>
                <CRow md={12} >
                    <CCol md={6}  >
                        <CRow className="justify-content-center" xs={{ gutter: 3 }}>
                            <CCol md={11} >
                                <Stack marginRight={5} marginLeft={5} marginTop={3} justifyContent="space-between">
                                    <Stack direction='row' alignItems="center" spacing={1}>
                                        <Avatar sx={{ bgcolor: orange[700] }} >
                                            <WorkHistoryOutlined />
                                        </Avatar>
                                        <AlertTitle><strong>Work Type</strong></AlertTitle>
                                    </Stack>
                                    <hr />
                                </Stack>
                                <Stack direction='row' marginRight={5} marginLeft={5} >


                                    <FormControlLabel
                                        control={<Switch color="warning"
                                            checked={isMomentaryChecked}
                                            defaultChecked
                                            onChange={handleMomentaryChange} />}
                                        label="Momentary"
                                        labelPlacement="start"


                                    />
                                    <FormControlLabel
                                        control={<Switch color="warning"
                                            checked={isRegularJobChecked}
                                            onChange={handleRegularJobChange} />}
                                        label="Regular Job"
                                        labelPlacement="start"

                                    />
                                </Stack>
                                <Stack marginRight={5} marginLeft={5} marginTop={6} justifyContent="space-between">
                                    <Stack direction='row' alignItems="center" spacing={1}>
                                        <Avatar sx={{ bgcolor: orange[700] }} >
                                            <Group />
                                        </Avatar>
                                        <AlertTitle><strong>User</strong></AlertTitle>
                                    </Stack>
                                    <hr />
                                </Stack>
                                <Stack direction='row' marginLeft={5} marginTop={2}>
                                    <FormControlLabel
                                        control={<Switch color="warning"
                                            checked={isAllGroupChecked}
                                            onChange={handleAllGroupChange} />}
                                        label="All Group"
                                        labelPlacement="start"
                                    />
                                    <FormControlLabel
                                        control={<Switch color="warning"
                                            checked={isAllUsersChecked}
                                            onChange={handleAllUsersChange} />}
                                        label="All Users"
                                        labelPlacement="start"
                                    />
                                    <FormControlLabel
                                        control={<Switch color="warning"
                                            checked={isFreelancerChecked}
                                            onChange={handleFreelancerChange} />}
                                        label="Freelancer"
                                        labelPlacement="start"
                                    />

                                </Stack>
                                <Stack marginRight={5} marginLeft={5} marginTop={2}>
                                    <CRow className="justify-content-center" >
                                        <CCol>
                                            <TextField
                                                fullWidth
                                                select
                                                label="Group"
                                                //helperText={validations?.labelColor}
                                                variant="standard"
                                                color="warning"
                                                onChange={e => handleOnSelectGroupElement(e.target.value)}
                                            >
                                                {allGroups.map((option) => (
                                                    <MenuItem key={option.id} value={option.id}>
                                                        {option.groupName}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        </CCol>
                                        <CCol >
                                            <TextField
                                                fullWidth
                                                disabled={isAllGroupChecked}
                                                select
                                                label="Users"
                                                //helperText={validations?.labelColor}
                                                variant="standard"
                                                color="warning"
                                                onChange={e => setSelectedUser(e.target.value)}
                                            >
                                                {groupUsers.map((option) => (
                                                    <MenuItem key={option.Id} value={option.User.Id}>
                                                        {option.User.FullName}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        </CCol>
                                    </CRow >
                                </Stack>

                            </CCol>
                            <CCol md={11} >
                                {
                                    selectedTemplate && selectedTemplate != null ?
                                        <Stack marginRight={5} marginLeft={5} marginTop={6} justifyContent="space-between">
                                            <Stack marginBottom={5} spacing={2}>
                                                <Stack >
                                                    <TextField
                                                        fullWidth
                                                        id="taskName"
                                                        label="Task Name"
                                                        variant="standard"
                                                        value={taskName}
                                                        onChange={e => setTaskName(e.target.value)}
                                                    />

                                                </Stack>
                                            </Stack>
                                            <CRow>
                                                {
                                                    selectedTemplate.objectTypes && selectedTemplate.objectTypes.map(item => {
                                                        return (
                                                            <CCol md={6}>
                                                                <h6 className="text-center">{item.align.toLocaleUpperCase()} Row {item.order}</h6>
                                                                <CCol md={12}  >
                                                                    <Stack marginBottom={5} spacing={2}>
                                                                        <Stack >
                                                                            <TextField
                                                                                fullWidth
                                                                                id="Notification"
                                                                                label={getKeyByValue(ELEMENT_TYPE, item.type)}
                                                                                variant="standard"
                                                                                disabled={item.type !== ELEMENT_TYPE.USER_INPUT}
                                                                                value={item.value}
                                                                                onChange={e => handleOnChangeFormValues(item, e.target.value)}
                                                                            />
                                                                        </Stack>
                                                                    </Stack>
                                                                </CCol>
                                                            </CCol>
                                                        )
                                                    })
                                                }
                                            </CRow>
                                            <Stack direction='row' alignItems="center" spacing={1}>
                                                <Avatar sx={{ bgcolor: orange[700] }} >
                                                    <NotificationsActiveIcon />
                                                </Avatar>
                                                <AlertTitle><strong>Notification</strong></AlertTitle>
                                            </Stack>
                                            <hr />
                                            <CCol md={12}  >
                                                <Stack marginBottom={5} spacing={2}>
                                                    <Stack >
                                                        <TextField
                                                            fullWidth
                                                            id="Notification"
                                                            label="Notification"
                                                            variant="standard"
                                                            value={notification}
                                                            //helperText={validations?.projectName}
                                                            onChange={e => setNotification(e.target.value)}
                                                        />

                                                    </Stack>
                                                </Stack>
                                            </CCol>
                                        </Stack>
                                        :
                                        <Stack marginRight={5} marginLeft={5} marginTop={6} justifyContent="space-between">
                                            <h6 className="text-center">Please select a template</h6>
                                        </Stack>
                                }
                            </CCol>
                        </CRow>
                    </CCol >
                    <CCol md={6} >
                        <CRow className="justify-content-center" xs={{ gutter: 5 }}>
                            <CCol md={10} >
                                <Stack marginRight={5} marginLeft={5} marginTop={3} justifyContent="space-between">
                                    <Stack direction='row' alignItems="center" spacing={1}>
                                        <Avatar sx={{ bgcolor: isMomentaryChecked ? null : orange[700] }} >
                                            <CalendarMonthRoundedIcon />
                                        </Avatar>
                                        <AlertTitle><strong>Date Picker</strong></AlertTitle>
                                    </Stack>
                                    <hr />
                                </Stack>
                                <Stack>

                                    <LocalizationProvider dateAdapter={AdapterDayjs} >
                                        {isMomentaryChecked ?
                                            <Stack direction='row' justifyContent="space-around" >
                                                <Stack>
                                                    <Stack direction='row' alignItems="center" spacing={1}>
                                                        <AlertTitle><strong>Start Date</strong></AlertTitle>
                                                    </Stack>
                                                    <DateCalendar value={null} disabled />
                                                </Stack>
                                                <Stack >
                                                    <AlertTitle><strong>End Date</strong></AlertTitle>
                                                    <DateCalendar value={null} disabled />
                                                </Stack>
                                            </Stack>
                                            :
                                            <Stack direction='row' justifyContent="space-around" >
                                                <Stack>
                                                    <Stack direction='row' alignItems="center" spacing={1}>
                                                        <AlertTitle><strong>Start Date</strong></AlertTitle>
                                                    </Stack>
                                                    <DateCalendar value={value} onChange={(newValue) => setValue(newValue)} />
                                                </Stack>
                                                <Stack >
                                                    <AlertTitle><strong>End Date</strong></AlertTitle>
                                                    <DateCalendar value={value} onChange={(newValue) => setValue(newValue)} />
                                                </Stack>
                                            </Stack>
                                        }
                                    </LocalizationProvider>
                                </Stack>
                            </CCol >
                        </CRow>
                    </CCol >

                </CRow>

            </CCard >

            <Stack marginBottom={3} className="d-flex justify-content-center " direction="row" spacing={2}>
                <Chip sx={{ fontSize: 18, padding: 3, paddingLeft: 1, paddingRight: 1 }} color="warning" label="Create"
                    onClick={() => { handleSendMessage(); }}
                    icon={<CheckCircleOutlineIcon sx={{ fontSize: 35 }} />}
                />
            </Stack>
        </Layout >
    )


}
export default TaskAssignment;
