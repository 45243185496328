import React, { useContext } from 'react';
import { AppContext } from '../../contextapi/AppProvider';
import Content from '../Content';
import {
    BrowserRouter,
    Routes,
    Route,
    Navigate
} from "react-router-dom";
import Login from '../pages/Login';
import Report from '../pages/Report';
import User from '../pages/Adminpanel/User';
import VisionTool from '../pages/VisionTool';
import PickingTool from '../pages/PickingTool';
import AnnotationTool from '../pages/AnnotationTool/AnnotationTool';
import Settings from '../pages/Adminpanel/SystemSettings/Settings';
import PublicRoutes from './PublicRoutes';
import ProtectedRoutes from './ProtectedRoutes';
import NotFound from '../NotFound';
import AnnotationProject from '../pages/AnnotationTool/AnnotationProject';
import TaskManagement from '../pages/TaskManagement';
import TaskManagementAdminPanel from '../pages/TaskManagementAdminPanel';
import TaskAssignment from '../pages/TaskAssignment';
 
const Router = () => {
    const { routerData, userInfo } = useContext(AppContext);
    const AuthRoute = ({ children, ...rest }) => {
        return (!userInfo.isUserAuth || (userInfo.isUserAuth && userInfo.isUserAuth === "false")) ? <Navigate to="/login" /> : children
    }

    return (
        <BrowserRouter>
            <Routes>
                {/*Redirect login if route not match*/}
                <Route path="*" element={<AuthRoute><NotFound /></AuthRoute>} />

                {/** Protected Routes */}
                {/** Wrap all Route under ProtectedRoutes element */}
                <Route path="/" element={<ProtectedRoutes />}>
                    <Route exact path="/report" element={<Report />} />
                    <Route exact path="/user" element={<User />} />
                    <Route exact path="/settings" element={<Settings />} />
                    <Route exact path="/visiontool" element={<VisionTool />} />
                    <Route exact path="/pickingtool" element={<PickingTool />} />
                    <Route exact path='/annotationproject' element={<AnnotationProject />} />
                    <Route exact path="/annotationtool" element={<AnnotationTool />} />
                    <Route exact path="/taskmanagement" element={<TaskManagement />} /> 
                    <Route exact path="/taskmanagementadminpanel" element={<TaskManagementAdminPanel />} /> 
                    <Route exact path="/taskassignment" element={<TaskAssignment />} /> 

                    {
                        routerData && routerData.length > 0 && 
                        <Route exact path="/" element={ <Navigate to={`${routerData[0]?.route}`} /> } />
                    }
                    <Route path="/" element={<Content routeId={routerData[0]?.id} />} />
                    {
                        routerData.map(item => {
                            return <Route exact
                                path={item.route}
                                name={item.text}
                                key={item.id}
                                element={<Content routeId={item.id} />} >
                            </Route>
                        })
                    }
                </Route>
                <Route path="login" element={<PublicRoutes />}>
                    <Route exact path="/login" element={<Login />} />
                </Route>

            </Routes>
        </BrowserRouter>
    );
}

export default Router;