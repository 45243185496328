import Layout from "../../Layout";
import ErrorPage from "./ErrorPage";
import IMUPage from "./IMUPage";
import OCRPage from "./OCRPage";
import PickingPage from "./PickingPage";

const PickingTool = (props) => {
    return (
        <Layout>
            <PickingPage />
            <IMUPage />
            {/* <OCRPage />
            <ErrorPage /> */}
        </Layout>
    );
}

export default PickingTool;