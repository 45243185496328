import { CRow } from '@coreui/react';
import React, { useContext, useState, useEffect } from 'react';
import { AppContext } from '../contextapi/AppProvider';
import Layout from './Layout';
import d from "../helpers/mockData.json"
import { useLocation, useSearchParams } from 'react-router-dom';
import { generateRequestFilterObjectFromQueryParams } from '../helpers/filter-helper';
import { ServiceHelper } from '../helpers/service-helper';
import BaseRenderer from '../components/BaseRenderer';

const Content = (props) => {
    const { setLoading, dropDownFilterData, formData, setFormData, userInfo } = useContext(AppContext);
    const [searchParams, setSearchParams] = useSearchParams();
    let location = useLocation();

    useEffect(() => {
        setLoading(true);
        // get page definition
        setFormData([])
        if (process.env.REACT_APP_MODE === "DEV") {
            setFormData(d.Data)
            setLoading(false);
        }
        else {
            var filters = generateRequestFilterObjectFromQueryParams(searchParams)
            var _serviceHelper = new ServiceHelper(`${window.app.config.BASE_API_URL}/dashboardcontent`,
                {
                    dashboardpageid: props.routeId ?? 1,
                    filters
                },
                {
                    headers: { 'Authorization': userInfo?.token }
                });
            _serviceHelper._post(
                (response) => {
                    setLoading(false);
                    setFormData([...response.data])
                },
                (err) => {
                    setLoading(false);
                    setFormData([])
                    console.log(err);
                }
            )
        }
    }, [dropDownFilterData, location])

    return(
        <Layout {...props}>
            <CRow>
                {
                    formData?.map((item, index) => {
                        return <BaseRenderer renderItem={item} key={index} {...props} />
                    })
                }
            </CRow>
        </Layout>);
}

export default React.memo(Content);