import React, { useContext } from 'react';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import BlockUi from "react-block-ui";
import "react-block-ui/style.css";
import { AppContext } from '../contextapi/AppProvider';
import Header from './Header';
import Footer from './Footer';
import Sidebar from './Sidebar';

const Layout = (props) => {
  const { loading } = useContext(AppContext);

  return (
      <BlockUi
          blocking={loading}
          message="Yükleniyor"
          keepInView
      >
          <div>
              <Sidebar />
              <div className="wrapper d-flex flex-column min-vh-100 bg-light">
                  <Header {...props} />
                  <div className="body flex-grow-1 px-3">
                      {props.children}
                      <ToastContainer />
                  </div>
                  <Footer />
              </div>
          </div>
      </BlockUi>
  )
}

export default Layout;