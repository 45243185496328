import { CCard, CCardBody, CCardFooter, CCardHeader, CCol, CRow } from '@coreui/react';
import React, { useState, useContext, useEffect } from 'react';
import Chart from "react-apexcharts";
import ApexCharts from 'apexcharts';
import { generateRequestFilterObjectFromQueryParamsAndDropdownData } from '../helpers/filter-helper';
import { ServiceHelper } from '../helpers/service-helper';
import { useSearchParams } from 'react-router-dom';
import { AppContext } from '../contextapi/AppProvider';
import { useTranslation } from 'react-i18next';

const DynamicLoadedChartRenderer = (props) => {
    const { t } = useTranslation();
    const { dropDownFilterData } = useContext(AppContext);
    const [searchParams, setSearchParams] = useSearchParams();

    const updateSecondChart = (sourceChart) => {

        var series = [];
        var seriesIndex = 0;
        var colors = []
        if (sourceChart.w.globals.selectedDataPoints[0]) {
            var selectedPoints = sourceChart.w.globals.selectedDataPoints;
            for (var i = 0; i < selectedPoints[seriesIndex].length; i++) {
                var selectedIndex = selectedPoints[seriesIndex][i];
                var seriesData = sourceChart.w.config.series[seriesIndex];
                series.push({
                    name: seriesData.data[selectedIndex][props.element.additionalParams.secondChart.updateOptions.nameField],
                    data: seriesData.data[selectedIndex][props.element.additionalParams.secondChart.updateOptions.dataField]
                })
                colors.push(seriesData.data[selectedIndex].color)
            }
            if (series.length === 0) series = [{
                data: []
            }]
            return ApexCharts.exec(props.element.additionalParams.secondChart.id, 'updateOptions', {
                series: series,
                colors: colors,
                fill: {
                    colors: colors
                }
            })
        }
    }

    useEffect(() => {
        if (props.element.url) {
            props.setLoading(true);
            var filters = generateRequestFilterObjectFromQueryParamsAndDropdownData(searchParams, dropDownFilterData)
            var _serviceHelper = new ServiceHelper(props.element.url, filters);
            _serviceHelper._post(
                (response) => {
                    // var data = { 
                    //     ...firstChartOptions,
                    //     series: [...firstChartOptions.series]
                    //  };

                    var data = JSON.parse(JSON.stringify(firstChartOptions));
                    data.series[0].data = response.data.firstChart?.data
                    setFirstChartOptions(data);
                    props.setLoading(false);
                },
                (err) => {
                    //setFirstChartOptions([]);
                    console.log(err);
                    props.setLoading(false);
                })
        }
        else {
            setFirstChartOptions(props.element.items);
        }
    }, [])

    const [firstChartOptions, setFirstChartOptions] = useState({
        series: [{
            data: props.element.additionalParams.firstChart?.data
        }],
        options: {
            chart: {
                type: 'bar',
                height: 380,
                id: props.element.additionalParams.firstChart?.id,
                events: {
                    dataPointSelection: function (e, chart, opts) {
                        var secondChart = document.querySelector(`#apexcharts${props.element.additionalParams.secondChart?.id}`);
                        var firstChart = document.querySelector(`#apexcharts${props.element.additionalParams.firstChart?.id}`);

                        if (opts.selectedDataPoints[0].length === 1) {
                            if (secondChart.classList.contains("active")) {
                                updateSecondChart(chart)
                            } else {
                                firstChart.classList.add("chart-quarter-activated")
                                secondChart.classList.add("active");
                                updateSecondChart(chart)
                            }
                        } else {
                            updateSecondChart(chart)
                        }

                        if (opts.selectedDataPoints[0].length === 0) {
                            firstChart.classList.remove("chart-quarter-activated")
                            secondChart.classList.remove("active");
                        }

                    },
                    updated: function (chart) {
                        updateSecondChart(chart)
                    }
                }
            },
            plotOptions: {
                bar: {
                    distributed: true,
                    horizontal: true,
                    barHeight: '75%',
                    dataLabels: {
                        position: 'bottom'
                    }
                }
            },
            colors: props.element.additionalParams.firstChart?.colors,
            dataLabels: {
                enabled: true,
                textAnchor: 'start',
                style: {
                    colors: ['#fff']
                },
                formatter: function (val, opt) {
                    return t(`dynamic.graphics.labels.${opt.w.globals.labels[opt.dataPointIndex]}`, opt.w.globals.labels[opt.dataPointIndex])
                },
                offsetX: 0,
                dropShadow: {
                    enabled: true
                }
            },
            states: {
                normal: {
                    filter: {
                        type: 'desaturate'
                    }
                },
                active: {
                    allowMultipleDataPointsSelection: true,
                    filter: {
                        type: 'darken',
                        value: 1
                    }
                }
            },
            tooltip: {
                x: {
                    show: false
                },
                y: {
                    title: {
                        formatter: function (val, opts) {
                            return t(`dynamic.graphics.labels.${opts.w.globals.labels[opts.dataPointIndex]}`, opts.w.globals.labels[opts.dataPointIndex])
                        }
                    }
                }
            },
            stroke: {
                width: 1,
                colors: ['#fff']
            },
            yaxis: {
                labels: {
                    show: false
                }
            },
            title: {
                text: '',
                offsetX: 15
            },
            subtitle: {
                text: '',
                offsetX: 15
            },
            legend: {
                show: false
            }
        }
    })

    const [secondChartOptions, setSecondChartOptions] = useState({
        series: [{
            data: []
        }],
        options: {
            chart: {
                id: props.element.additionalParams.secondChart?.id,
                height: 400,
                width: '100%',
                type: 'bar',
                stacked: true
            },
            plotOptions: {
                bar: {
                    columnWidth: '50%',
                    horizontal: false
                }
            },
            legend: {
                show: false
            },
            grid: {
                yaxis: {
                    lines: {
                        show: false,
                    }
                },
                xaxis: {
                    lines: {
                        show: true,
                    }
                }
            },
            yaxis: {
                labels: {
                    show: false
                }
            },
            title: {
                text: '',
                offsetX: 10
            },
            tooltip: {
                x: {
                    formatter: function (val, opts) {
                        return t(`dynamic.graphics.labels.${opts.w.globals.seriesNames[opts.seriesIndex]}`, opts.w.globals.seriesNames[opts.seriesIndex])
                    }
                },
                y: {
                    title: {
                        formatter: function (val, opts) {
                            return t(`dynamic.graphics.labels.${opts.w.globals.labels[opts.dataPointIndex]}`, opts.w.globals.labels[opts.dataPointIndex])
                        }
                    }
                }
            }
        }
    })
    return (
        <>
            <CCard className="custom-card h-100">
                {props.element.cardInfo && props.element.cardInfo.headers && props.element.cardInfo.headers.length > 0 &&
                    <CCardHeader>
                        <CRow>
                            {
                                props.element.cardInfo.headers.map((p, key) => {
                                    return <CCol key={key} md={p.column}><h6 style={p.style?.inlineStyle}>{t(`dynamic.graphics.headers.${p.text}`, p.text.toString())}</h6></CCol>
                                })
                            }
                        </CRow>
                    </CCardHeader>
                }
                <CCardBody>
                    <CRow>
                        <CCol md={6}>
                            <div id={props.element.additionalParams?.firstChart?.id}>
                                <Chart
                                    options={firstChartOptions.options}
                                    series={firstChartOptions.series}
                                    type="bar"
                                    height={400}
                                />
                            </div>
                        </CCol>
                        <CCol md={6}>
                            <div id={props.element.additionalParams?.secondChart?.id}>
                                <Chart
                                    options={secondChartOptions.options}
                                    series={secondChartOptions.series}
                                    type="bar"
                                    height={400}
                                />
                            </div>
                        </CCol>
                    </CRow>
                </CCardBody>
                {props.element.cardInfo && props.element.cardInfo.footers && props.element.cardInfo.footers.length > 0 &&
                    <CCardFooter>
                        <CRow>
                            {
                                props.element.cardInfo.footers.map((p, key) => {
                                    return <CCol key={key} md={p.column}><h6 style={p.style?.inlineStyle}>{t(`dynamic.graphics.footers.${p.text}`, p.text.toString())}</h6></CCol>
                                })
                            }
                        </CRow>
                    </CCardFooter>
                }
            </CCard>
        </>);
}

export default DynamicLoadedChartRenderer;