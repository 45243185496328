import { CCard, CCardBody, CCardFooter, CCardHeader, CCol, CNavItem, CRow } from '@coreui/react';
import React, { useState, useContext, useEffect } from 'react';
import Chart from "react-apexcharts";
import { useTranslation } from 'react-i18next';
import { AppContext } from '../contextapi/AppProvider';
import { generateRequestFilterObjectFromQueryParamsAndDropdownData } from '../helpers/filter-helper';
import { ServiceHelper } from '../helpers/service-helper';
import { useSearchParams } from 'react-router-dom';

const PieChartRenderer = (props) => {
    console.log("Chart Props", props);
    const { dropDownFilterData } = useContext(AppContext)
    const [searchParams, setSearchParams] = useSearchParams()
    const { t } = useTranslation();

    const [chartOptions, setChartOptions] = useState({
        options: {},
        series: [],
    })

    useEffect(() => {
        let obj = { ...chartOptions };
        obj.options = {
            chart: {
                height: 380,
                type: 'donut',
            },
            labels: [],
            colors: [],
            dataLabels: {
                enabled: true,
                dropShadow: {
                    enabled: false,
                }
            },
            legend: {
                show: false
            },
            plotOptions: {
                pie: {
                    donut: {
                        labels: {
                            show: true,
                            name: {
                                show: true
                            },
                            value: {
                                show: true
                            }
                        }
                    }
                }
            },
            responsive: [{
                breakpoint: 480,
                options: {
                    chart: {
                        width: 100
                    },
                    legend: {
                        position: 'bottom',
                    }
                }
            }]
        }

        if (props.element.url) {
            props.setLoading(true);
            var filters = generateRequestFilterObjectFromQueryParamsAndDropdownData(searchParams, dropDownFilterData)
            var _serviceHelper = new ServiceHelper(props.element.url, filters);
            _serviceHelper._post(
                (response) => {
                    obj.series = response.data.series;
                    obj.options.labels = response.data.labels;
                    obj.options.colors = response.data.colors;
                    setChartOptions(obj)
                    props.setLoading(false);
                },
                (err) => {
                    console.log(err);

                    obj.series = [];
                    obj.options.labels = [];
                    obj.options.colors = [];
                    setChartOptions(obj)
                    props.setLoading(false);
                })
        }
        else {
            obj.series = props.element.series;
            obj.options.labels = props.element.labels;
            obj.options.colors = props.element.colors;
            setChartOptions(obj)       
        }
    }, [])

    return (
        <>
            <CCard className="custom-card h-100">
                {props.element.cardInfo && props.element.cardInfo.headers && props.element.cardInfo.headers.length > 0 &&
                    <CCardHeader>
                        <CRow>
                            {
                                props.element.cardInfo.headers.map((p, key) => {
                                    return <CCol key={key} md={p.column}><h6 style={p.style?.inlineStyle}>{t(`dynamic.graphics.headers.${p.text}`, p.text.toString())}</h6></CCol>
                                })
                            }
                        </CRow>
                    </CCardHeader>
                }
                <CCardBody>
                    <Chart
                        options={chartOptions.options}
                        series={chartOptions.series}
                        type="donut"
                        height={350}
                    />
                </CCardBody>
                {props.element.cardInfo && props.element.cardInfo.footers && props.element.cardInfo.footers.length > 0 &&
                    <CCardFooter>
                        <CRow>
                            {
                                props.element.cardInfo.footers.map((p, key) => {
                                    return <CCol key={key} md={p.column}><h6 style={p.style?.inlineStyle}>{t(`dynamic.graphics.footers.${p.text}`, p.text.toString())}</h6></CCol>
                                })
                            }
                        </CRow>
                    </CCardFooter>
                }
            </CCard>
        </>);
}

export default PieChartRenderer;