import { CCard, CCardBody, CCardFooter, CCardHeader, CCol, CRow } from '@coreui/react';
import React, { useState, useContext, useEffect } from 'react';
import Chart from "react-apexcharts";
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { AppContext } from '../contextapi/AppProvider';
import { generateRequestFilterObjectFromQueryParamsAndDropdownData } from '../helpers/filter-helper';
import { ServiceHelper } from '../helpers/service-helper';

const LineChartRenderer = (props) => {
    const { dropDownFilterData, currentLanguageCode } = useContext(AppContext)
    const [searchParams, setSearchParams] = useSearchParams()
    const [componentData, setComponentData] = useState({ ...props })
    const { t } = useTranslation();

    const [chartOptions, setChartOptions] = useState({
        options: {},
        series: [],
    })

    useEffect(() => {
        let obj = { ...chartOptions };
        obj.options = {
            chart: {
                stacked: false,
            },
            stroke: {
                width: [5, 5],
                curve: 'smooth'
            },
            plotOptions: {
                bar: {
                    columnWidth: '50%'
                }
            },
            colors: [],
            fill: {
                type: 'gradient',
                gradient: {
                    shade: 'light',
                    type: "vertical",
                    shadeIntensity: 0.25,
                    inverseColors: true,
                    stops: [100, 0]
                },
            },
            labels: [],
            markers: {
                size: 0
            },
            xaxis: {
                type: 'category'
            },
            yaxis: {
                title: {
                    text: '',
                }
            },
            tooltip: {
                shared: true,
                intersect: false,
                y: {
                    formatter: function (y) {
                        if (typeof y !== "undefined") { return y.toFixed(0); } return y;
                    }
                }
            },
            legend: {
                labels: {
                    useSeriesColors: true
                },
                markers: {
                    customHTML: [
                        function () { return '' },
                        function () { return '' },
                        function () { return '' }
                    ]
                }
            }
        }

        if (componentData.element.url) {
            props.setLoading(true);
            var filters = generateRequestFilterObjectFromQueryParamsAndDropdownData(searchParams, dropDownFilterData);
            var _serviceHelper = new ServiceHelper(`${componentData.element.url}`, filters);
            _serviceHelper._post(
                (response) => {
                    console.log(response)
                    obj.series = response.data.series.map(item => {
                        item.name = t(`dynamic.graphics.labels.${item.name}`, item.name.toString())
                        return item
                    });
                    obj.options.labels = response.data.labels.map(item => {
                        return t(`dynamic.graphics.labels.${item}`, item.toString())
                    });;
                    obj.options.colors = response.data.colors;

                    var tempComponentData = { ...componentData };
                    if (response.data.headers)
                        tempComponentData.element.cardInfo = {
                            ...tempComponentData.element.cardInfo,
                            headers: response.data.headers//[{ column: 6, inlineStyle: {}, text: "Test" }, { column: 6, style: { inlineStyle: {textAlign: "right"}}, text: "Test" }]
                        }
                    if (response.data.footers)
                        tempComponentData.element.cardInfo = {
                            ...tempComponentData.element.cardInfo,
                            footers: response.data.footers //[{ column: 12, inlineStyle: {}, text: "Test" }]
                        }

                    setChartOptions(obj)
                    setComponentData(tempComponentData);

                    props.setLoading(false);
                },
                (err) => {
                    console.log(err);
                    obj.series = [];
                    obj.options.labels = [];
                    obj.options.colors = [];
                    setChartOptions(obj)

                    props.setLoading(false);
                })
        }
        else {
            obj.series = componentData.element.series;
            obj.options.labels = componentData.element.labels;
            obj.options.colors = componentData.element.colors;
            setChartOptions(obj)
        }
    }, [currentLanguageCode])

    return (
        <>
            <CCard className="custom-card h-100">
                {componentData.element.cardInfo && componentData.element.cardInfo.headers && componentData.element.cardInfo.headers.length > 0 &&
                    <CCardHeader>
                        <CRow>
                            {
                                componentData.element.cardInfo.headers.map((p, key) => {
                                    return <CCol key={key} md={p.column}><h6 style={p.style?.inlineStyle}>{t(`dynamic.graphics.headers.${p.text}`, p.text.toString())}</h6></CCol>
                                })
                            }
                        </CRow>
                    </CCardHeader>
                }
                <CCardBody>
                    <div id="chart">
                    <Chart
                        options={chartOptions.options}
                        series={chartOptions.series}
                        type={componentData.element.type}
                        height={350}
                    />
                    </div>
                </CCardBody>
                {componentData.element.cardInfo && componentData.element.cardInfo.footers && componentData.element.cardInfo.footers.length > 0 &&
                    <CCardFooter>
                        <CRow>
                            {
                                componentData.element.cardInfo.footers.map((p, key) => {
                                    return <CCol key={key} md={p.column}><h6 style={p.style?.inlineStyle}>{t(`dynamic.graphics.footers.${p.text}`, p.text.toString())}</h6></CCol>
                                })
                            }
                        </CRow>
                    </CCardFooter>
                }
            </CCard>
        </>);
}

export default LineChartRenderer;