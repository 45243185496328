import { cilCheckCircle, cilXCircle } from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import { CCol, CListGroup, CListGroupItem, CRow, CWidgetStatsE, CWidgetStatsF } from '@coreui/react';
import React, { useContext, useEffect, useRef, useState } from 'react'

import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';

import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr'
import { LOCAL_STORAGE_KEYS } from '../../../helpers/constants';
import axios from 'axios';
import { AppContext } from '../../../contextapi/AppProvider';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const PickingPage = () => {
    const userInfo = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.USER_INFO));
    const navigate = useNavigate();
    const carouselRef = useRef(null);
    const { setLoading } = useContext(AppContext);
    const { t } = useTranslation();
    const [activeProductId, setActiveProductId] = useState("");
    const [carouselData, setCarouselData] = useState([]);
    const [activeIndex, setActiveIndex] = useState(0);
    const style = {
        "paddingRight": "10px",
        "paddingTop": "10px",
        "paddingLeft": "5px"
    }

    const responsive = {
        0: { items: 1 },
        568: { items: 2 },
        1024: { items: 3 },
    };

    useEffect(() => {
        signalRHubConnection();
    }, [])

    useEffect(() => {
        getProductData();
    }, [activeProductId])

    const signalRHubConnection = async () => {
        const connection = new HubConnectionBuilder()
            .withUrl(`${window.app.config.PICKING_TOOL.PRODUCT.SIGNALR_HUB_API_URL}/${window.app.config.PICKING_TOOL.PRODUCT.SIGNALR_HUB_NAME}`,)
            .withAutomaticReconnect()
            .build();

        connection.on(`${window.app.config.PICKING_TOOL.PRODUCT.SIGNALR_METHOD_NAME}_${userInfo.id}`, (response) => {

            console.log("Product SignalRResponse", response);
            if (response) {

                setActiveProductId(response);
                // set active product number
            }
        })

        await connection.start();
    }

    const getProductData = () => {
        setLoading(true);
        axios.get(`${window.app.config.PICKING_TOOL.PRODUCT.API_URL}`, {
            headers: {
                UserId: userInfo.id
            }
        })
            .then((response) => {
                setLoading(false);
                var sortedData = response.data.sort((firstItem, secondItem) => firstItem.order - secondItem.order);
                var preparedData = prepareCarouselData(sortedData);
                setActiveProductId(response.data.filter(item => item.isQueued)[0].id);
                setCarouselData(preparedData);
            })
            .catch((err) => {
                console.log(err);
            });
    }

    const prepareCarouselData = (data) => {

        return data.map((item, index) => {
            var activeClassName = "";
            var activeProduct = data.find(p => p.id === activeProductId);
            if (activeProduct && activeProduct != null) {
                if (item.order < activeProduct.order) {
                    item.glogiIntegration = true;
                    item.voiceIntegration = true;
                }
            }

            if (item.id === activeProductId) {
                activeClassName = "active__carousel";
                setActiveIndex(index);
            }


            return (
                <div style={style}>
                    <CWidgetStatsE
                        className={`mb-3 ${activeClassName}`}
                        value={
                            <>
                                <CRow>
                                    <CCol md={12}>
                                        <img src={item.imagePath} width={200} />
                                    </CCol>
                                    <CListGroup flush>
                                        <CListGroupItem>
                                            <CRow>
                                                <CCol md={6} style={{ textAlign: "left" }}>
                                                    {t("pages.picking.productName")}
                                                </CCol>
                                                <CCol md={6} className="fnt-light" style={{ textAlign: "right" }}>
                                                    {item.name}
                                                </CCol>
                                            </CRow>
                                        </CListGroupItem>
                                        <CListGroupItem>
                                            <CRow>
                                                <CCol md={6} style={{ textAlign: "left" }}>
                                                    {t("pages.picking.address")}
                                                </CCol>
                                                <CCol md={6} className="fnt-light" style={{ textAlign: "right" }}>
                                                    {item.address}
                                                </CCol>
                                            </CRow>
                                        </CListGroupItem>
                                        {/* <CListGroupItem>
                                            <CRow>
                                                <CCol md={6} style={{ textAlign: "left" }}>
                                                    Qty
                                                </CCol>
                                                <CCol md={6} className="fnt-light" style={{ textAlign: "right" }}>
                                                    {item.quantity}
                                                </CCol>
                                            </CRow>
                                        </CListGroupItem> */}
                                        <CListGroupItem>
                                            {/* <CRow>
                                                <CCol md={6} style={{ textAlign: "left" }}>
                                                    Glogi Integration
                                                </CCol>
                                                <CCol md={6} style={{ textAlign: "right" }}>
                                                    <CIcon icon={item.glogiIntegration ? cilCheckCircle : cilXCircle} style={{ color: item.glogiIntegration ? "green" : "red" }} />
                                                </CCol>
                                            </CRow> */}
                                        </CListGroupItem>
                                        {/* <CListGroupItem>
                                            <CRow>
                                                <CCol md={6} style={{ textAlign: "left" }}>
                                                    Voice Integration
                                                </CCol>
                                                <CCol md={6} style={{ textAlign: "right" }}>
                                                    <CIcon icon={item.voiceIntegration ? cilCheckCircle : cilXCircle} style={{ color: item.voiceIntegration ? "green" : "red" }} />
                                                </CCol>
                                            </CRow>
                                        </CListGroupItem> */}
                                    </CListGroup>
                                </CRow>
                            </>
                        }
                    />
                </div>
            )
        })
    }

    return (

        <CRow>
            <CCol md={12} ref={carouselRef}>
                <AliceCarousel
                    startIndex={0}
                    mouseTracking={false}
                    ssrSilentMode={false}
                    disableDotsControls
                    activeIndex={activeIndex}
                    items={carouselData}
                    responsive={responsive}
                    controlsStrategy={"default"}
                    keyboardNavigation
                />
            </CCol>
        </CRow>
    )
}

export default React.memo(PickingPage)