import { CCol } from '@coreui/react';
import React, { useState } from 'react';
import BlockUi from 'react-block-ui';
import { ELEMENT_TYPES } from '../helpers/constants';
import AreaChartRenderer from './AreaChartRenderer';
import CardRenderer from './CardRenderer';
import DataTableRenderer from './DataTableRenderer';
import DonutChartRenderer from './DonutChartRenderer';
import DropdownRenderer from './DropdownRenderer';
import DynamicLoadedChartRenderer from './DynamicLoadedChartRenderer';
import EmployeeMapRenderer from './EmployeeMapRenderer';
import HeatMapRenderer from './HeatMapRenderer';
import HorizontalBarChartRenderer from './HorizontalBarChartRenderer';
import LineChartRenderer from './LineChartRenderer';
import PieChartRenderer from './PieChartRenderer';
import TabDataTableRenderer from './TabDataTableRenderer';
import VerticalBarChartRenderer from './VerticalBarChartRenderer';

const BaseRenderer = ({ renderItem, keyIndex, ...rest }) => {
    const [loading, setLoading] = useState(false);
    return (
        <>
            <CCol md={renderItem.column} className="pb-4">
                <BlockUi
                    
                    blocking={loading}
                    message="Yükleniyor"
                    keepInView
                    className="h-100"
                >
                    {
                        (() => {
                            switch (renderItem.type) {
                                case ELEMENT_TYPES.CARD:
                                    return <CardRenderer key={keyIndex} {...rest} element={renderItem} setLoading={setLoading} />
                                case ELEMENT_TYPES.AREA:
                                    return <AreaChartRenderer key={keyIndex} {...rest} element={renderItem} setLoading={setLoading} />
                                case ELEMENT_TYPES.DONUT:
                                    return <DonutChartRenderer key={keyIndex} {...rest} element={renderItem} setLoading={setLoading} />
                                case ELEMENT_TYPES.LINE:
                                    return <LineChartRenderer key={keyIndex} {...rest} element={renderItem} setLoading={setLoading} />
                                case ELEMENT_TYPES.PIE:
                                    return <PieChartRenderer key={keyIndex} {...rest} element={renderItem} setLoading={setLoading} />
                                case ELEMENT_TYPES.BAR_DYNAMIC:
                                    return <DynamicLoadedChartRenderer key={keyIndex} {...rest} element={renderItem} setLoading={setLoading} />
                                case ELEMENT_TYPES.BAR_VERTICAL:
                                    return <VerticalBarChartRenderer key={keyIndex} {...rest} element={renderItem} setLoading={setLoading} />
                                case ELEMENT_TYPES.BAR_HORIZONTAL:
                                    return <HorizontalBarChartRenderer key={keyIndex} {...rest} element={renderItem} setLoading={setLoading} />
                                case ELEMENT_TYPES.DATATABLE:
                                    return <DataTableRenderer key={keyIndex} {...rest} element={renderItem} setLoading={setLoading} />
                                case ELEMENT_TYPES.TAB_DATATABLE:
                                    return <TabDataTableRenderer key={keyIndex} {...rest} element={renderItem} setLoading={setLoading} />
                                case ELEMENT_TYPES.DROPDOWN:
                                    return <DropdownRenderer key={keyIndex} {...rest} element={renderItem} setLoading={setLoading} />
                                case ELEMENT_TYPES.EMPLOYEE_MAP:
                                    return <EmployeeMapRenderer key={keyIndex} {...rest} element={renderItem} setLoading={setLoading} />
                                case ELEMENT_TYPES.HEAT_MAP:
                                    return <HeatMapRenderer key={keyIndex} {...rest} element={renderItem} setLoading={setLoading} />
                                default:
                                    return <div>Not Found</div>
                            }
                        })()
                    }
                </BlockUi>
            </CCol>
        </>
    );
}

export default BaseRenderer;