export const ELEMENT_TYPES = {
    CARD: "card",
    AREA: "area",
    DONUT: "donut",
    DATATABLE: "datatable",
    TAB_DATATABLE: "tab-datatable",
    BAR_VERTICAL: "bar-vertical",
    BAR_HORIZONTAL: "bar-horizontal",
    BAR_DYNAMIC: "bar-dynamic",
    LINE: "line",
    PIE: "pie",
    DROPDOWN: "dropdown",
    EMPLOYEE_MAP: "employee_map",
    HEAT_MAP: "heat_map"
}

export const LANGUAGES = [
    {
        code: "fr",
        name: "Français",
        country_code: "fr"
    },
    {
        code: "tr",
        name: "Türkçe",
        country_code: "tr"
    },
    {
        code: "en",
        name: "English",
        country_code: "gb"
    }
]
export const LOCAL_STORAGE_KEYS = {
    ROUTER_DATA: "ROUTER_DATA",
    IS_USER_AUTH: "IS_USER_AUTH_DYNAMIC",
    USER_INFO: "USER_INFO_DYNAMIC",
}

export const MAP_TYPE = {
    "EMPLOYEE": 1,
    "FOOT_PRINT": 2,
    "HEAT": 3
}

export const HEAT_MAP_FILTERS = {
    "MONTHLY": "2",
    "WEEKLY": "1",
    "DAILY": "3",
    "CUSTOM": "4"
}

export const ICON_TYPE = {
    ICON: 1,
    IMAGE: 2
}

export const IMU_TYPE = {
    STANDING: 0,
    WORKING: 1,
    WALKING: 2,
    NONE: 3
}


export const ELEMENT_TYPE = {
    TEXT: 0,
    USER_INPUT: 1,
    DEVICE_INPUT: 2,
    NONE: 3
}

export const TASK_TEMPLATE_OBJECTS = {
    LEFT_ROW_1: {Order: 1, Key: "left_row_1", Align: "left", Type:ELEMENT_TYPE.NONE },
    RIGHT_ROW_1: {Order: 1, Key: "right_row_1", Align: "right", Type:ELEMENT_TYPE.NONE},

    LEFT_ROW_2: {Order: 2, Key: "left_row_2", Align: "left", Type:ELEMENT_TYPE.NONE},
    RIGHT_ROW_2: {Order: 2, Key: "right_row_2", Align: "right", Type:ELEMENT_TYPE.NONE},

    LEFT_ROW_3: {Order: 3, Key: "left_row_3", Align: "left", Type:ELEMENT_TYPE.NONE},
    RIGHT_ROW_3: {Order: 3, Key: "right_row_3", Align: "right", Type:ELEMENT_TYPE.NONE},

    LEFT_ROW_4: {Order: 4, Key: "left_row_4", Align: "left", Type:ELEMENT_TYPE.NONE},
    RIGHT_ROW_4: {Order: 4, Key: "right_row_4", Align: "right", Type:ELEMENT_TYPE.NONE},
}