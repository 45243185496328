import React from 'react'
import Layout from '../../../Layout';
import { CCol, CRow } from '@coreui/react';
import LineSettings from './LineSettings';
import OvertimeDistribution from './OvertimeDistribution';
import SystemSetting from './SystemSetting';
import { Card, Dropdown } from 'react-bootstrap';
import { useEffect } from 'react';
import axios from 'axios';
import { useState } from 'react';
import { useContext } from 'react';
import { AppContext } from '../../../../contextapi/AppProvider';
import Select from 'react-select';


const Settings = () => {
    const { userInfo } = useContext(AppContext);
    const [branches, setBranches] = useState([]);
    const [branchId, setBranchId] = useState();
    const [branchName, setBranchName] = useState();
 
    const handleChange = (branch) => {
        var branchId = branch.value.split(",")[0]
        var branchName = branch.value.split(",")[1] 
        setBranchId(branchId)
        setBranchName(branchName)
    };
 
    useEffect(() => { 
        axios.get(window.app.config.SETTINGS.LIST_AUTHORIZE_BRANCHES, {
            headers: { "Authorization": userInfo.token }
        }).then((response) => {
            var data = response.data;
            setBranches(data);
            setBranchId(data[0].value)
            console.log(data);
        }).catch((response) => {
            console.log("error", response);
        });
    }, []) 
  
    const drpOptions = []
    branches.forEach(e => {
        drpOptions.push({
            value : e.value + "," + e.name,
            label : e.name
        })
    }) 
    return (
        <Layout>
            <CRow className='mb-3'>
                <CCol md={12}>  
                    {<Select className=' col-md-12' placeholder={(typeof branchName == 'undefined' ? branches.length > 0 ? branches[0].name : branchName : branchName)}
                        onChange={handleChange}
                         options={drpOptions}> 
                    </Select>}

                </CCol>
            </CRow>
            <CRow>
                <CCol md={12}>
                    <SystemSetting branchId={branchId}/>
                </CCol>
            </CRow>
            <CRow>
                <CCol md={6}>
                    <LineSettings branchId={branchId} />
                </CCol>
                <CCol md={6}>
                    <OvertimeDistribution branchId={branchId}/>
                </CCol>
            </CRow>

        </Layout>);

}
export default Settings;
